import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

function PageLoader({ showLoader = false }) {
  useEffect(() => {
    const html = document.querySelector('html');
    const body = document.querySelector('body');
    if (showLoader) {
      html.classList.add('schroll-block');
      body.classList.add('schroll-block');
    } else {
      html.classList.remove('schroll-block');
      body.classList.remove('schroll-block');
    }
  }, [showLoader]);

  return (
    <>
      {showLoader ? (
        <div className="page-loader">
          <Spinner className="spinner" animation="border" />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default PageLoader;
