import React from 'react';
// import { PopupButton, useCalendlyEventListener } from 'react-calendly';
import RightAero from '../../assets/images/right-aero.gif';
// import { config } from '../../config/config';

export default function StickyPurchaseButton({
  invert = false,
  disabled = false,
  className,
  originalPrice = '',
  givenPrice = '',
  text = 'Purchase Now',
  ...props
}) {
  // useCalendlyEventListener({
  //   onProfilePageViewed: () => console.log('onProfilePageViewed'),
  //   onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
  //   onEventTypeViewed: () => console.log('onEventTypeViewed'),
  //   onEventScheduled: e => console.log(e.data),
  // });

  return (
    <button
      {...props}
      disabled={disabled}
      className={`mx-0 mt-3 sticky-purchase-button ${
        invert ? 'purchase-button-invert' : ''
      } justify-content-between w-100 ${className || ''}`}
    >
      <div className="d-flex flex-row align-items-center justify-content-center w-100">
        {givenPrice && originalPrice ? (
          <div className="text-message">
            <span>{givenPrice ? givenPrice + ' Rs' : 'FREE'}</span>
            <span>{originalPrice ? originalPrice + ' Rs' : ''}</span>
          </div>
        ) : (
          <></>
        )}
        <button className="d-flex flex-row ms-4" type="button" onClick={() => console.log('sticky purchase now')}>
          {/* <div>{text.split(' ')[0]}</div>
          <div>{text.split(' ')[1]}</div> */}
          {text}
          <img style={{ height: '23px' }} src={RightAero} alt="" />
        </button>
      </div>
    </button>
  );
}
