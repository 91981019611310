import React, { useEffect, useState } from 'react';
// import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FiCheckSquare } from 'react-icons/fi';

import StickyPurchaseButton from '../../common/buttons/StickyPurchaseButton';
import { ScrollVisibilityProvider } from '../../common/ScrollVisibilityProvider';

import Poster from '../../assets/images/poster.jpeg';
import Dashboard from '../../assets/images/dashboard.png';
import MentorImage from '../../assets/images/mentorImage.png';
import UserForm from './UserForm';
import Success from './Success';
import SectionedButton from '../../common/buttons/SectionedButton';
import { headline, persons, services, subHeadline, subHeadlineBold } from './contants';
import { useLocation, useNavigate } from 'react-router-dom';
import { config } from '../../config/config';
import ReactPlayer from 'react-player';

function LandingPage() {
  const [states, setStates] = useState({
    showForm: false,
    showSuccess: false,
  });
  const location = useLocation();
  const navigate = useNavigate();

  // Parse the query string into an object
  const queryParams = new URLSearchParams(location.search);

  // Retrieve specific query parameters by name
  const success = queryParams.get('paymentSuccess');

  useEffect(() => {
    if (success === 'true') {
      handleStates('showSuccess', true);
    }
  }, []);

  const handleStates = (key, value) => {
    setStates(prev => {
      return { ...prev, [key]: value };
    });
  };

  return (
    <ScrollVisibilityProvider>
      <div className="landing-page">
        <div className="top d-flex flex-column justify-content-center align-items-center text-center">
          <div className="title fw-7">{headline}</div>
          <div className="tag-line f-s1-75 mb-4">
            {subHeadline}
            <b>{subHeadlineBold}</b>
          </div>
          <div className="video-size">
            <ReactPlayer
              controls
              playing
              url={config.video.url}
              // light={
              //   config.video.thumbnailUrl ? (
              //     <img style={{ width: '50vw', height: '30vw' }} src={config.video.thumbnailUrl} />
              //   ) : (
              //     ''
              //   )
              // }
              width="100%"
              height="100%"
            />
          </div>

          <SectionedButton
            invert
            text="Register Now!"
            className=""
            label="Purchase Now"
            // onClick={() => handleStates('showForm', true)}
            onClick={() => (window.location.href = config.razorpay.pageUrl)}
          />
        </div>

        {/* <div className="services mt-3">
          <div className="services-img hidden-component">
            <img className="" src={ServiceImage} alt="Services" />
          </div>
          <div className="services-list">
            <div>
              {services.map((service, index) => {
                return (
                  <div
                    key={"" + service + index}
                    className="d-flex flex-row justify-content-start hidden-component"
                  >
                    <GoDotFill size={20} />
                    <span className="my-2 mx-3">{service}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}

        <div className="white-section d-flex flex-column justify-content-center align-items-center">
          <div className="heading mt-4 mb-2">What You Will Get</div>
          <div className="row w-78-90">
            {services.map((service, index) => (
              <div key={'' + service.title + index} className="col-6 col-md-4 col-lg-4 p-0">
                <div className="service-card hidden-component text-center">
                  <img className="service-image" src={service.image} alt="Students" />
                  <span className="service-text my-1">{service.title}</span>
                  <span className="service-desc my-1">{service.desc}</span>
                </div>
              </div>
            ))}
          </div>

          <SectionedButton
            invert
            text="Register Now!"
            className="hidden-component"
            label="Purchase Now"
            // onClick={() => handleStates('showForm', true)}
            onClick={() => (window.location.href = config.razorpay.pageUrl)}
          />
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center pb-4">
          <div className="heading mt-4 mb-2">Who is this Course for?</div>
          <div className="row w-78-90">
            {persons.map((person, index) => (
              <div key={'' + person.name + index} className="col-6 col-md-4 col-lg-4 p-0">
                <div className="person-card hidden-component text-center">
                  {/* <img
                    className="person-image"
                    src={person.image}
                    alt="Students"
                  /> */}
                  <span className="person-text my-1 py-2">{person.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="heading mt-4 mb-2">
            Create Dashboards for all types of data
          </div>
          <div className="row w-85-95">
            {[0, 9, 8, 7, 9, 10].map((num, index) => (
              <div
                key={"example" + num + index}
                className="col-12 col-sm-6 col-md-6 col-lg-4 p-0"
              >
                <div className="dashboard-card hidden-component text-center">
                  <img
                    className="dashboard-image"
                    src={Dashboard}
                    alt="Dashboard"
                  />
                  <span className="dashboard-text py-1 my-2">
                    Business & Sales Dashboards {num}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div> */}

        <div className="white-section d-flex flex-column justify-content-center align-items-center">
          <div className="heading mt-4 mb-2">What you&apos;ll learn</div>
          <div className="syllabus-points hidden-component p-4 w-60-90 justify-content-between flex-row flex-wrap">
            {[1, 2, 3, 4, 5, 6].map((num, index) => (
              <div key={'syllabus' + num + index} className="syllabus-point w-48">
                <FiCheckSquare className="w-2" />
                <span>Lorem ipsum dolor sit amet. {num}</span>
              </div>
            ))}
          </div>

          <SectionedButton
            invert
            text="Register Now!"
            className="hidden-component"
            label="Purchase Now"
            // onClick={() => handleStates('showForm', true)}
            onClick={() => (window.location.href = config.razorpay.pageUrl)}
          />
        </div>

        <div className="mentor-info d-flex flex-column justify-content-center align-items-center mb-2">
          <div className="heading mt-4 mb-2">Know Your Mentor</div>
          <div className="flex-768">
            <div className="image hidden-component">
              <img src={MentorImage} alt="mentorImage" />
            </div>
            <div className="content ms-4">
              <div>
                <div className="menter-title hidden-component">
                  {'Hi, I am {menter_name}, your master class instructor.'}
                </div>
                <div className="details my-3 hidden-component">
                  I am a practicing analyst with more than 7 years of experience along with a sound experience in
                  pricing, global supply chain, and customer experience domains of Fortune 500 companies.
                </div>
                <div className="details my-3 hidden-component">
                  Have conducted more than 100 interviews and thus I do know what exactly it takes to crack a
                  high-paying business Analyst job. I have been using standard industry tools like SQL, Power BI, VBA,
                  and Microsoft Excel which are important for roles like the business analytics.
                </div>
              </div>
            </div>
          </div>
          <SectionedButton
            invert
            text="Register Now!"
            className="hidden-component"
            label="Purchase Now"
            // onClick={() => handleStates('showForm', true)}
            onClick={() => (window.location.href = config.razorpay.pageUrl)}
          />
        </div>

        <StickyPurchaseButton
          invert
          text="Register Now!"
          // onClick={() => handleStates('showForm', true)}
          onClick={() => (window.location.href = config.razorpay.pageUrl)}
        />
      </div>

      <UserForm
        show={states.showForm}
        onSuccess={() => (window.location.href = config.razorpay.pageUrl)}
        handleHide={() => handleStates('showForm', false)}
      />
      <Success
        show={states.showSuccess}
        headline={headline}
        handleHide={() => {
          handleStates('showSuccess', false);
          navigate('/');
        }}
      />
    </ScrollVisibilityProvider>
  );
}

export default LandingPage;
