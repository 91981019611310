import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { courseTypes } from '../../utils/constants';
import { config } from '../../config/config';

function UserForm({ handleHide, onSuccess, ...props }) {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    // profession: 'Select',
    // education: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    // profession: '',
    // education: '',
  });
  const [isSubmitClick, setIsSubmitClick] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [loading, setLoading] = useState(false);
  // const professions = [
  //   { value: 'Select', label: 'Select' },
  //   { value: 'Student', label: 'Student' },
  //   { value: 'Job Seeker', label: 'Job Seeker' },
  //   { value: 'Supply Chain Analysts', label: 'Supply Chain Analysts' },
  //   { value: 'Business Professionals', label: 'Business Professionals' },
  //   { value: 'Data Enthusiasts', label: 'Data Enthusiasts' },
  //   { value: 'Market Researchers', label: 'Market Researchers' },
  //   { value: 'Other', label: 'Other' },
  // ];

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
      .required('Phone number is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    // education: Yup.string().required('Education is required'),
    // profession: Yup.string().required('Profession is required').notOneOf(['Select'], 'Please select your profession'),
  });

  const checkValidation = async () => {
    return validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        setErrors({});
        return true;
      })
      .catch(validationErrors => {
        // Validation failed, set errors
        const newErrors = {};
        validationErrors.inner.forEach(error => {
          newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
        return false;
      });
  };

  const handleSubmit = async () => {
    setIsSubmitClick(true);
    const isValidData = await checkValidation();

    if (isValidData) {
      // --> API call
      try {
        const url = `${config.baseUrl}/api/v1/user/`;
        setLoading(true);
        const response = await axios.post(url, { ...formData, courseType: courseTypes.DATA_ANALIST });
        if (response?.status) {
          onSuccess();
          handleSuccess();
        } else {
          setRequestError(response?.message || 'Something went wrong');
        }
        setLoading(false);
      } catch (error) {
        console.error('error: ', error);
        setRequestError(error?.response?.data?.message || error?.message || 'Something went wrong');
        setLoading(false);
      }
    }
  };

  const handleSuccess = () => {
    setFormData({
      name: '',
      phoneNumber: '',
      email: '',
      // education: '',
      // profession: 'Select',
    });
    setErrors({
      name: '',
      phoneNumber: '',
      email: '',
      // education: '',
      // profession: '',
    });
    setIsSubmitClick(false);
    handleHide();
  };

  const handleChange = event => {
    setFormData(prev => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  useEffect(() => {
    if (isSubmitClick) {
      checkValidation();
    }
    setRequestError('');
  }, [formData]);

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleHide}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="spinner">
            <Spinner />
          </div>
        ) : (
          <></>
        )}
        <Form>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              isInvalid={errors.name}
              placeholder="Enter name"
            />
            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPhoneNo">
            <Form.Label>Phone No.</Form.Label>
            <Form.Control
              type="number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              isInvalid={errors.phoneNumber}
              placeholder="Enter phone no."
            />
            <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              isInvalid={errors.email}
              placeholder="Enter email"
            />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>
          {requestError ? <Form.Label className="d-block text-danger text-center">{requestError}</Form.Label> : <></>}

          {/* <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Education</Form.Label>
            <Form.Control
              type="text"
              name="education"
              value={formData.education}
              onChange={handleChange}
              isInvalid={errors.education}
              placeholder="Enter your education"
            />
            <Form.Control.Feedback type="invalid">{errors.education}</Form.Control.Feedback>
          </Form.Group> */}

          {/* <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Select Professions</Form.Label>
            <Form.Select
              isInvalid={errors.profession}
              aria-label="Default select example"
              name="profession"
              onChange={handleChange}
              defaultValue={formData.profession}
            >
              {professions.map((profession, index) => {
                return (
                  <option key={'' + profession.value + index} value={profession.value}>
                    {profession.label}
                  </option>
                );
              })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{errors.profession}</Form.Control.Feedback>
          </Form.Group> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit" onClick={handleSubmit}>
          Register
        </Button>
        <Button variant="secondary" onClick={handleHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UserForm;
