import Error404 from '../Eroor 404';
import LandingPage from '../LandingPage';
import LandingPageBcom from '../LandingPageBcom';
import Freelancing from '../Freelancing';
import HomePage from '../Home';

const routes = [
  {
    title: 'Home Page',
    path: '/home-page',
    Component: HomePage,
    exact: true,
  },
  {
    title: 'Freelancing Page',
    path: '/freelancing-page',
    Component: Freelancing,
    exact: true,
  },
  {
    title: 'Landing Page',
    path: '/landing-page',
    Component: LandingPage,
    exact: true,
  },
  {
    title: 'Landing Page',
    path: '/',
    Component: LandingPageBcom,
    exact: true,
  },
  {
    title: 'Error 404',
    path: '/auth/404',
    Component: Error404,
    exact: true,
  },
];

export default routes;
