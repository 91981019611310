/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import { Route, Navigate, Routes, BrowserRouter as Routers } from 'react-router-dom';
// import Error404 from 'pages/Eroor 404';
import routes from './routeArray';
// import PrivateRoute from './PrivateRoute';

function Router() {
  return (
    <Routers>
      <Routes>
        {routes.map(({ path, Component, exact }) => (
          <Route path={path} key={path} exact={exact} element={<Component />} />
          ))}
        <Route element={<Navigate replace to="/auth/404" />} />
      </Routes>
    </Routers>
  );
}

export default Router;
