import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, FormLabel, Modal, Form, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { courseTypes } from '../../utils/constants';
import { config } from '../../config/config';
import { Field, Formik, Form as FromFormik, ErrorMessage } from 'formik';

function UserForm({ handleHide, onSuccess, ...props }) {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    currentlyDo: '',
    stream: '',
    income: '',
    desiredIncome: '',
    heardBefore: '',
    // profession: 'Select',
    // education: '',
  });
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState('');
  // const professions = [
  //   { value: 'Select', label: 'Select' },
  //   { value: 'Student', label: 'Student' },
  //   { value: 'Job Seeker', label: 'Job Seeker' },
  //   { value: 'Supply Chain Analysts', label: 'Supply Chain Analysts' },
  //   { value: 'Business Professionals', label: 'Business Professionals' },
  //   { value: 'Data Enthusiasts', label: 'Data Enthusiasts' },
  //   { value: 'Market Researchers', label: 'Market Researchers' },
  //   { value: 'Other', label: 'Other' },
  // ];

  const handleSubmit = async values => {
    try {
      const url = `${config.baseUrl}/user`;
      setLoading(true);
      const response = await axios.post(url, {
        ...values,
        timestamp: new Date().toLocaleString(),
        pageUrl: window.location.href,
      });
      if (response?.status) {
        onSuccess();
        handleHide();
      } else {
        setRequestError(response?.message || 'Something went wrong');
      }
      setLoading(false);
    } catch (error) {
      console.error('error: ', error);
      setRequestError(error?.response?.data?.message || error?.message || 'Something went wrong');
      setLoading(false);
    }
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleHide}>
      <Modal.Header className="fs-7 me-2" style={{ fontWeight: '500' }} closeButton>
        Please answer the short questionnaire so we have the details needed to help you on your call (takes 2-3
        minutes).
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="spinner">
            <Spinner />
          </div>
        ) : (
          <></>
        )}
        <Formik
          initialValues={formData}
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            name: Yup.string().required('Name is required'),
            phoneNumber: Yup.string()
              .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
              .required('Phone number is required'),
            email: Yup.string().email('Invalid email format').required('Email is required'),
            currentlyDo: Yup.string().required('Required'),
            stream: Yup.string().required('Required'),
            income: Yup.string().required('Required'),
            desiredIncome: Yup.number().required('Desired monthly income is required'),
            heardBefore: Yup.string().required('Required'),
          })}
        >
          {({ errors, status, touched, values, setFieldValue }) => (
            <FromFormik>
              <div className="d-flex flex-column mb-2">
                <FormLabel
                  className="form-label mt-2 me-5 mb-0 fs-6"
                  style={{ fontSize: 'Poppins', fontWeight: '500' }}
                >
                  Name :
                </FormLabel>
                <div>
                  <Field
                    name="name"
                    type="text"
                    placeholder="Enter full name"
                    className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name="name" component="div" className="invalid-feedback fs-8" />
                </div>
              </div>
              <div className="d-flex flex-column mb-2">
                <FormLabel className="form-label mt-2 me-5 mb-0 fs-6">Phone :</FormLabel>
                <div>
                  <Field
                    name="phoneNumber"
                    type="number"
                    placeholder="Enter your phone number"
                    className={'form-control' + (errors.phoneNumber && touched.phoneNumber ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name="phoneNumber" component="div" className="invalid-feedback fs-8" />
                </div>
              </div>
              <div className="d-flex flex-column mb-3">
                <FormLabel className="form-label mt-2 me-5 mb-0 fs-6">Email :</FormLabel>
                <div>
                  <Field
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name="email" component="div" className="invalid-feedback fs-8" />
                </div>
              </div>
              <div className="d-flex flex-column mb-2">
                <FormLabel id="my-radio-group" className="form-label mt-2 me-5 mb-0 fs-6">
                  • Please describe what do you currently do?
                  <span className="text-danger fs-7 ms-3">
                    {errors.currentlyDo && touched.currentlyDo ? ' Required' : ''}
                  </span>
                </FormLabel>
                <div>
                  <div role="group" className="d-flex flex-column ms-2" aria-labelledby="my-radio-group">
                    <label>
                      <Field type="radio" name="currentlyDo" value="Working in Corporate" />
                      <span className="ms-2 fs-7">Working in Corporate</span>
                    </label>
                    <label>
                      <Field type="radio" name="currentlyDo" value="Student" />
                      <span className="ms-2 fs-7">Student</span>
                    </label>
                    <label>
                      <Field type="radio" name="currentlyDo" value="Unemployed" />
                      <span className="ms-2 fs-7">Unemployed</span>
                    </label>
                  </div>
                  <ErrorMessage name="currentlyDo" component="div" className="invalid-feedback fs-8" />
                </div>
              </div>
              <div className="d-flex flex-column mb-2">
                <FormLabel id="my-radio-group" className="form-label mt-2 me-5 mb-0 fs-6">
                  • Select your stream
                  <span className="text-danger fs-7 ms-3">{errors.stream && touched.stream ? ' Required' : ''}</span>
                </FormLabel>
                <div>
                  <div role="group" className="d-flex flex-column ms-2" aria-labelledby="my-radio-group">
                    <label>
                      <Field type="radio" name="stream" value="Bcom" />
                      <span className="ms-2 fs-7">Bcom</span>
                    </label>
                    <label>
                      <Field type="radio" name="stream" value="Mcom" />
                      <span className="ms-2 fs-7">Mcom</span>
                    </label>
                    <label>
                      <Field type="radio" name="stream" value="Other" />
                      <span className="ms-2 fs-7">Other</span>
                    </label>
                  </div>
                  <ErrorMessage name="stream" component="div" className="invalid-feedback fs-8" />
                </div>
              </div>
              <div className="d-flex flex-column mb-2">
                <FormLabel id="my-radio-group" className="form-label mt-2 me-5 mb-0 fs-6">
                  • What is your current monthly income?
                  <span className="text-danger fs-7 ms-3">{errors.income && touched.income ? ' Required' : ''}</span>
                </FormLabel>
                <div>
                  <div role="group" className="d-flex flex-column ms-2" aria-labelledby="my-radio-group">
                    <label>
                      <Field type="radio" name="income" value="No Income" />
                      <span className="ms-2 fs-7">No Income</span>
                    </label>
                    <label>
                      <Field type="radio" name="income" value="Rs 10-30K" />
                      <span className="ms-2 fs-7">Rs 10-30K</span>
                    </label>
                    <label>
                      <Field type="radio" name="income" value="Rs 30-50K" />
                      <span className="ms-2 fs-7">Rs 30-50K</span>
                    </label>
                    <label>
                      <Field type="radio" name="income" value="Rs 50K-1L" />
                      <span className="ms-2 fs-7">Rs 50K-1L</span>
                    </label>
                    <label>
                      <Field type="radio" name="income" value="Over 1L" />
                      <span className="ms-2 fs-7">Over 1L</span>
                    </label>
                  </div>
                  <ErrorMessage name="income" component="div" className="invalid-feedback fs-8" />
                </div>
              </div>
              <div className="d-flex flex-column mb-2">
                <FormLabel
                  className="form-label mt-2 me-5 mb-0 fs-6"
                  style={{ fontSize: 'Poppins', fontWeight: '500' }}
                >
                  What is your desired monthly income?
                </FormLabel>
                <div>
                  <Field
                    name="desiredIncome"
                    type="number"
                    placeholder="Enter your desired monthly income"
                    className={'form-control' + (errors.desiredIncome && touched.desiredIncome ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name="desiredIncome" component="div" className="invalid-feedback fs-8" />
                </div>
              </div>
              <div className="d-flex flex-column mb-2">
                <FormLabel id="my-radio-group" className="form-label mt-2 me-5 mb-0 fs-6">
                  • Have you ever heard of Data Analytics?
                  <span className="text-danger fs-7 ms-3">
                    {errors.heardBefore && touched.heardBefore ? ' Required' : ''}
                  </span>
                </FormLabel>
                <div>
                  <div role="group" className="d-flex flex-column ms-2" aria-labelledby="my-radio-group">
                    <label>
                      <Field type="radio" name="heardBefore" value="Yes" />
                      <span className="ms-2 fs-7">Yes</span>
                    </label>
                    <label>
                      <Field type="radio" name="heardBefore" value="No" />
                      <span className="ms-2 fs-7">No</span>
                    </label>
                  </div>
                  <ErrorMessage name="heardBefore" component="div" className="invalid-feedback fs-8" />
                </div>
              </div>
              <Modal.Footer className="mt-4 mb-0">
                <Button variant="primary" type="submit">
                  Book
                </Button>
                <Button variant="secondary" onClick={handleHide}>
                  Cancel
                </Button>
              </Modal.Footer>
            </FromFormik>
          )}
        </Formik>
        {requestError ? <FormLabel className="d-block text-danger text-center fs-6">{requestError}</FormLabel> : <></>}
      </Modal.Body>
    </Modal>
  );
}

export default UserForm;
