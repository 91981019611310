import React from 'react';

function Error404() {
  const notFound = 'https://quick-connect-assets.s3.ca-central-1.amazonaws.com/quick-connect-assets/404.png';
  return (
    <div className="error-container">
      <div className="unauth">
        <img src={notFound} alt="Not Found" />
        <br />
        <span>Sorry page not found</span>
      </div>
    </div>
  );
}

export default Error404;
