import React from "react";

export default function PurchaseButton({
  text = "Purchase now",
  invert = false,
  disabled = false,
  className,
  name,
  originalPrice = 99,
  realPrice = 999,
  ...props
}) {
  return (
    <div
      {...props}
      className={`d-flex justify-content-center w-100 ${className || ""}`}
    >
      <button
        onClick={() => console.log("Purchase now")}
        disabled={disabled}
        type="submit"
        className={`purchase-button mt-3 ${
          invert ? "purchase-button-invert" : ""
        }`}
        name={name || ""}
      >
        <div className="">{text}</div>
        <span>
          (Just{" "}
          <span
            className={`purchase-price  ${
              invert ? "text-success-light" : "text-success"
            }`}
          >
            Rs {originalPrice}
          </span>{" "}
          <span className="td-lt">Rs {realPrice}</span> Today)
        </span>
      </button>
    </div>
  );
}
