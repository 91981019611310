import React from 'react';
import SuccessGif from '../../assets/images/SuccessTrans.gif';
import { Modal, Button } from 'react-bootstrap';

function Success({ headline = '', handleHide, ...props }) {
  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleHide}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center px-4 pb-4">
        <img className="mb-4" src={SuccessGif} alt="Success" />
        <span className="text-center fw-7 fs-3">🎉 Congratulations! 🎉</span>
        <span className="text-center fw-5">
          You've taken the first step toward personalized guidance in your journey to Data Analytics Excellence by
          registering for 1-to-1 consulting:
        </span>
        <span className="text-center fw-5">📩 We'll contact you soon to schedule your consulting session.</span>
        <span className="text-center fw-7 fs-5">
          Get ready for a tailored experience that will help you achieve your goals. 🚀
        </span>
      </Modal.Body>
    </Modal>
  );
}

export default Success;
