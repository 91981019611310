// import * as Yup from 'yup';
// const Yup = require('yup');

// const envVarsSchema = Yup.object().shape({
//   REACT_APP_BASE_URL: Yup.string().required().describe('Base url of backend'),
//   REACT_APP_CALENDLY_MEETING_URL: Yup.string().required().describe('Calendly meeting url'),
// });

export const config = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  razorpay: {
    pageUrl: process.env.REACT_APP_RAZORPAY_PAGE_URL,
  },
  video: {
    url: process.env.REACT_APP_VIDEO_URL,
    thumbnailUrl: process.env.REACT_APP_THUMBNAIL_URL,
  },
  calendly: {
    meetingUrl: process.env.REACT_APP_CALENDLY_MEETING_URL,
  },
};

// Validate the data against the schema
// const envVars = envVarsSchema.validateSync(process.env, { abortEarly: false });

// export const validateEnvironment = async () => {
//   try {
//     console.log('validateEnvironment: ');
//     const envVars = await envVarsSchema.validate(data);
//     console.log('envVars: ', envVars);
//   } catch (error) {
//     console.error('error: ', error);
//   }
// };
