import React from 'react';
// import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FiCheckSquare } from 'react-icons/fi';

import PurchaseButton from '../../common/buttons/PurchaseButton';
import StickyPurchaseButton from '../../common/buttons/StickyPurchaseButton';
import { ScrollVisibilityProvider } from '../../common/ScrollVisibilityProvider';

import Poster from '../../assets/images/poster.jpeg';
import Student from '../../assets/images/students.png';
import JobSeeker from '../../assets/images/job_seekers.png';
import Dashboard from '../../assets/images/dashboard.png';
import MentorImage from '../../assets/images/mentorImage.png';

function LandingPage() {
  const persons = [
    {
      name: 'Students',
      image: Student,
    },
    {
      name: 'Job Seekers',
      image: JobSeeker,
    },
    {
      name: 'HR Professionals',
      image: JobSeeker,
    },
    {
      name: 'Business Owners',
      image: JobSeeker,
    },
    {
      name: 'Sales & Marketing Professionals',
      image: JobSeeker,
    },
    {
      name: 'Finance & Accounts Professionals',
      image: JobSeeker,
    },
  ];

  return (
    <ScrollVisibilityProvider>
      <div className="landing-page">
        <div className="top d-flex flex-column justify-content-center align-items-center text-center">
          <div className="title fw-7">LEARN TO BUILD INTERACTIVE DASHBOARDS TO ANALYSE & PRESENT DATA EFFECTIVELY</div>
          <div className="tag-line text-white f-s1-75">
            Master & Get Certified in the skill of visualizing numerical data into interactive dashboards without any
            prior knowledge required.
          </div>
          <img className="poster" src={Poster} alt="poster" />
          <PurchaseButton className="" label="Purchase Now" originalPrice={299} realPrice={999} />
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="heading mt-4 mb-2">Who is this Masterclass for?</div>
          <div className="row w-78-90">
            {persons.map(person => (
              <div className="col-6 col-md-4 col-lg-4 p-0">
                <div className="person-card hidden-component text-center">
                  <img className="person-image" src={person.image} alt="Students" />
                  <span className="person-text my-1">{person.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <PurchaseButton invert className="hidden-component" label="Purchase Now" originalPrice={299} realPrice={999} />

        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="heading mt-4 mb-2">Create Dashboards for all types of data</div>
          <div className="row w-85-95">
            {[0, 9, 8, 7, 9, 10].map(num => (
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 p-0">
                <div className="dashboard-card hidden-component text-center">
                  <img className="dashboard-image" src={Dashboard} alt="Dashboard" />
                  <span className="dashboard-text py-1 my-2">Business & Sales Dashboards {num}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="heading mt-4 mb-2">What you&apos;ll learn</div>
          <div className="syllabus-points hidden-component p-4 w-60-90 justify-content-between flex-row flex-wrap">
            {[1, 2, 3, 4, 5, 6].map(num => (
              <div className="syllabus-point w-48">
                <FiCheckSquare className="w-2" />
                <span>Lorem ipsum dolor sit amet. {num}</span>
              </div>
            ))}
          </div>
        </div>

        <PurchaseButton invert className="hidden-component" label="Purchase Now" originalPrice={299} realPrice={999} />

        <div className="mentor-info d-flex flex-column justify-content-center align-items-center">
          <div className="heading mt-4 mb-2">Know Your Mentor</div>
          <div className="flex-768">
            <div className="image hidden-component">
              <img src={MentorImage} alt="mentorImage" />
            </div>
            <div className="content ms-4">
              <div>
                <div className="menter-title hidden-component">
                  {'Hi, I am {menter_name}, your master class instructor.'}
                </div>
                <div className="details my-3 hidden-component">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi quaerat officiis eveniet numquam
                  quod voluptatibus, eos architecto error nisi quidem.
                </div>
                <div className="details my-3 hidden-component">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe maxime doloribus magni ex fugit itaque
                  quos eligendi. Quas nostrum omnis ullam blanditiis ratione velit modi ex officiis, quam rem harum,
                  consequuntur sequi esse eveniet ipsam dolore dolores beatae laboriosam? Nam.
                </div>
              </div>
            </div>
          </div>
        </div>

        <PurchaseButton invert className="hidden-component" label="Purchase Now" originalPrice={299} realPrice={999} />

        <StickyPurchaseButton invert realPrice={999} originalPrice={299} />
      </div>
    </ScrollVisibilityProvider>
  );
}

export default LandingPage;
