import React, { useEffect } from 'react';

export function ScrollVisibilityProvider ({ children }) {
  useEffect(() => {
    const handleScroll = () => {
      const components = document.querySelectorAll('.hidden-component');

      components.forEach(component => {
        const componentPosition = component.getBoundingClientRect().top;
        const scrollPosition = window.innerHeight;

        if (componentPosition < scrollPosition) {
          component.classList.add('slide-in');
        } else {
          component.classList.remove('slide-in');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return <div>{children}</div>;
};

export default ScrollVisibilityProvider;
