import React from 'react';
import './assets/scss/app.scss';
import Router from './pages/Router';

function App() {
  return <Router />;
}

export default App;


