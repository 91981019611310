import React from 'react';
// import { PopupButton } from 'react-calendly';
import LampIcon from '../../assets/images/lamp.png';

export default function SectionedButton({
  text = 'Enroll now',
  invert = false,
  disabled = false,
  className,
  name,
  isClickHereShow = false,
  clickHereText = 'Click here to enroll',
  ...props
}) {
  return (
    <button {...props} className={`w-75-100 m-auto border-0 bg-transparent ${className || ''}`}>
      {/* <PopupButton
        url={process.env.REACT_APP_CALENDLY_MEETING_URL}
        rootElement={document.getElementById('root')}
        className="w-100 border-0 bg-transparent"
        text="book now"
      /> */}
      <div className={`sectioned-button d-flex justify-content-center py-3  ${invert ? 'purchase-button-invert' : ''}`}>
        <div className="blue-div-text">{text}</div>
        <div className="d-flex flex-row">
          {/* <h6 className="">FREE DEMO</h6> */}
          <div className="d-flex flex-row align-items-center">
            {/* <img src={LampIcon} alt="" style={{ height: '1.5rem' }} /> */}
            <span className="red-h6-text pt-1">(Limited Seats)</span>
            {/* <img src={LampIcon} alt="" style={{ height: '1.5rem' }} /> */}
          </div>
        </div>

        {/* <div className="d-flex justify-content-center w-90 mb-3">
          <div
            disabled={disabled}
            type="submit"
            className={`purchase-button mt-2 w-100 ${invert ? 'purchase-button-invert' : ''}`}
            name={name || ''}
          >
            <div className="">{text}</div>
            {isClickHereShow ? <span className="td-ul">({clickHereText})</span> : <></>}
          </div>
        </div> */}
      </div>
    </button>
  );
}
