import Student from '../../assets/images/students.png';
import JobSeeker from '../../assets/images/job_seekers.png';
import MentorImagePng from '../../assets/images/mentor.png';
import CertiImage from '../../assets/images/certification.png';
import ResumeImage from '../../assets/images/resume.png';
import CareerHelpImage from '../../assets/images/careerHelp.png';
import InterviewImage from '../../assets/images/interview.png';
import AccessibleImage from '../../assets/images/accessible_v2.png';

export const headline = 'From BCom to Data Maestro: Your Path to Data Analytics Excellence';
export const subHeadline =
  'Join our data analytics course designed exclusively for BCom students. Acquire the prowess to translate numbers into narratives, making your ';
export const subHeadlineBold = 'BCom degree more impactful than ever.';

export const persons = [
  {
    name: 'Students',
    image: Student,
  },
  {
    name: 'Job Seekers',
    image: JobSeeker,
  },
  {
    name: 'Supply Chain Analysts',
    image: JobSeeker,
  },
  {
    name: 'Business Professionals',
    image: JobSeeker,
  },
  {
    name: 'Market Researchers',
    image: JobSeeker,
  },
  {
    name: 'Data Enthusiasts',
    image: JobSeeker,
  },
];

export const services = [
  {
    title: 'Learn from the Best',
    desc: 'Get guidance from experienced instructors.',
    image: MentorImagePng,
  },
  {
    title: 'Earn a Recognized Certificate',
    desc: 'Boost your resume with a valid credential.',
    image: CertiImage,
  },
  {
    title: 'Impressive Resumes',
    desc: 'Stand out with professionally crafted CVs.',
    image: ResumeImage,
  },
  {
    title: 'Ongoing Career Help',
    desc: 'Receive support for your job search and growth.',
    image: CareerHelpImage,
  },
  {
    title: 'Mock Interviews',
    desc: 'Prepare with mock interview sessions.',
    image: InterviewImage,
  },
  {
    title: 'Always Accessible',
    desc: 'Revisit lessons anytime with lifetime session recordings.',
    image: AccessibleImage,
  },
];
