import React, { useEffect, useRef, useState } from 'react';
import { ImPointRight } from 'react-icons/im';

import StickyPurchaseButton from '../../common/buttons/StickyPurchaseButton';
import { ScrollVisibilityProvider } from '../../common/ScrollVisibilityProvider';

import Poster from '../../assets/images/poster.jpeg';
import Dashboard from '../../assets/images/dashboard.png';
// import MentorImage from '../../assets/images/mentorImage.png';
import MentorImage from '../../assets/images/mentor.jpg';
import MentorImage2 from '../../assets/images/mentor2.jpg';
import MathIcon from '../../assets/images/math.png';

import LessSalary from '../../assets/images/wage.png';
import HighSalary from '../../assets/images/high_salary.png';
import Salary from '../../assets/images/income.png';
import Confused from '../../assets/images/placement.png';

import PlayIcon from '../../assets/images/play-button.png';
// import VideoThumb from '../../assets/images/poster.png';
import VideoThumb from '../../assets/images/thumbnail.png';
import pdfIcon from '../../assets/images/pdf.png';
import UserForm from './UserForm';
import Success from './Success';
import SectionedButton from '../../common/buttons/SectionedButton';
import { headline, months, monthsFullNames, monthsShortNames, persons, services } from './contants';
import { useLocation, useNavigate } from 'react-router-dom';
import { config } from '../../config/config';
import ReactPlayer from 'react-player';
import { Player, ControlBar, PlayToggle, BigPlayButton } from 'video-react';
import '../../../node_modules/video-react/dist/video-react.css';
import axios from 'axios';
import { courseTypes } from '../../utils/constants';
import { Spinner } from 'react-bootstrap';
import PageLoader from '../../common/PageLoader';
import { PopupModal } from 'react-calendly';

function LandingPage() {
  const playerRef = useRef(null);
  const [states, setStates] = useState({
    showForm: false,
    showSuccess: false,
    videoTimeArray: [],
    videoStateId: '',
    isVideoPause: false,
    videoStartTime: 0,
    videoTotalTime: 0,
    showLoader: false,
  });
  const location = useLocation();
  const navigate = useNavigate();

  // Parse the query string into an object
  const queryParams = new URLSearchParams(location.search);

  // Retrieve specific query parameters by name
  const success = queryParams.get('paymentSuccess');
  const registeredSuccess = queryParams.get('registeredSuccess');

  useEffect(() => {
    // const acadioUserId = localStorage.getItem('acadioUserId') ? JSON.parse(localStorage.getItem('acadioUserId')) : '';

    // if (!acadioUserId) {
    storeUserId();
    // } else {
    //   console.log('acadio User Id already exists');
    // }

    if (registeredSuccess === 'true') {
      handleStates('showSuccess', true);
    }
  }, []);

  const handleStates = (key, value) => {
    setStates(prev => {
      return { ...prev, [key]: value };
    });
  };

  const syllabusArray = [
    {
      title: 'lorem Ipsum',
      desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sit temporibus architecto omnis ea molestias tempore amet, iste est, mollitia ipsam dolorem iure! Aliquid, ad ullam. Voluptatibus iste repellendus voluptatum eius.',
    },
    {
      title: 'lorem Ipsum 2',
      desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sit temporibus architecto omnis ea molestias tempore amet, iste est, mollitia ipsam dolorem iure! Aliquid, ad ullam. Voluptatibus iste repellendus voluptatum eius.',
    },
    {
      title: 'lorem Ipsum 3',
      desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sit temporibus architecto omnis ea molestias tempore amet, iste est, mollitia ipsam dolorem iure! Aliquid, ad ullam. Voluptatibus iste repellendus voluptatum eius.',
    },
    {
      title: 'lorem Ipsum 4',
      desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sit temporibus architecto omnis ea molestias tempore amet, iste est, mollitia ipsam dolorem iure! Aliquid, ad ullam. Voluptatibus iste repellendus voluptatum eius.',
    },
    {
      title: 'lorem Ipsum 4',
      desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sit temporibus architecto omnis ea molestias tempore amet, iste est, mollitia ipsam dolorem iure! Aliquid, ad ullam. Voluptatibus iste repellendus voluptatum eius.',
    },
    {
      title: 'lorem Ipsum 4',
      desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sit temporibus architecto omnis ea molestias tempore amet, iste est, mollitia ipsam dolorem iure! Aliquid, ad ullam. Voluptatibus iste repellendus voluptatum eius.',
    },
  ];

  const storeUserId = async () => {
    try {
      const url = `${config.baseUrl}/page-views`;
      const response = await axios.post(url, { pageUrl: window.location.href, timestamp: new Date().toLocaleString() });
      if (response?.status) {
        localStorage.setItem('acadioUserId', JSON.stringify(response?.data?.data?._id));
      } else {
        console.error(response?.message || 'Something went wrong');
      }
    } catch (error) {
      console.error('storeUserId error: ', error);
    }
  };

  const storeData = async (url, data) => {
    try {
      const finalUrl = `${config.baseUrl}${url}`;
      const response = await axios.post(finalUrl, data);
      console.log('response: ', response);
      return response?.data;
    } catch (error) {
      console.error('storeData error: ', error);
      error?.data;
    }
  };

  const handleSubmit = async (buttonCount = 1, buttonType = 'Page') => {
    // --> API call
    // handleStates('showForm', true);
    handleStates('showLoader', true);
    try {
      const response = await storeData('/button-click', {
        pageUrl: window.location.href,
        timestamp: new Date().toLocaleString(),
        buttonCount,
        buttonType,
      });
      if (response?.status) {
        console.log('handleSubmit Status true');
        handleStates('showForm', true);
      } else {
        console.error(response?.message || 'Something went wrong');
      }
      handleStates('showLoader', false);
    } catch (error) {
      console.error('handleSubmit error: ', error);
      // handleStates('showLoader', false);
    }
  };

  const calculateTotalTime = videoTimeArray => {
    return videoTimeArray.reduce((acc, timeObj) => {
      const { startMiliSecTime, pauseMiliSecTime } = timeObj;

      if (startMiliSecTime !== undefined && pauseMiliSecTime !== undefined) {
        acc += pauseMiliSecTime - startMiliSecTime;
      }
      return acc;
    }, 0);
  };

  let flag = true;
  const handleTimeUpdate = async () => {
    // Get the current time of the video
    const currentTime = playerRef.current.getState().player.currentTime;
    console.log('Current time:', currentTime);

    if (flag) {
      flag = false;
      setTimeout(async () => {
        flag = true;
        // const videoTotalTime = states.videoTotalTime + new Date().getTime() - states.videoStartTime;
        // console.log('videoStartTime: ', states.videoStartTime);
        // console.log('videoTotalTime: ', videoTotalTime);
        // handleStates('videoTotalTime', videoTotalTime);

        if (states.videoStateId) {
          await storeData('/video', { _id: states.videoStateId, totalTime: currentTime });
        }

        // if (states.isVideoPause) handleStates('videoStartTime', 0);

        // const totalTime = calculateTotalTime(states);
      }, 3000);
    }
  };

  return (
    <ScrollVisibilityProvider>
      <PageLoader showLoader={states.showLoader} />
      {/* position: absolute; top: 50%; left: 50%; z-index: 10; background-color: #000000aa; */}
      <div className="landing-page">
        <div className="bg-black w-100">
          <div className="d-flex flex-column justify-content-center align-items-center text-center pt-3">
            <div className="title">
              FROM{' '}
              <span className="title-underline">
                <u>BCom/MCom</u>
              </span>{' '}
              TO{' '}
              <span className="title-underline">
                <u>DATA ANALYSTS:</u>
              </span>
            </div>
            <div className="title">
              <span className="title-underline">
                <u>2X MORE PAY</u>
              </span>{' '}
              THAN YOUR TRADITIONAL{' '}
              <span className="title-underline">
                <u>BCom/MCom</u>
              </span>{' '}
              JOBS
            </div>
            <div className="title">
              GET{' '}
              <span className="title-underline">
                <u>FREE CONSULTANCY</u>
              </span>
            </div>
            {/* <div className="video-size my-3 rounded">
              <ReactPlayer
                controls
                playing
                className="rounded"
                url={config.video.url}
                light={<img className="video-size rounded" src={VideoThumb} />}
                playIcon={
                  <img style={{ height: 'min(4rem, 10vw)', position: 'absolute' }} className="rounded" src={PlayIcon} />
                }
                width="100%"
                height="100%"
                config={{
                  file: {
                    attributes: {
                      preload: 'auto',
                    },
                  },
                }}
              />
            </div> */}
            <div className="video-size my-3 rounded">
              <Player
                ref={playerRef}
                className="rounded"
                preload="auto"
                autoPlay={false}
                poster={VideoThumb}
                aspectRatio="16:9"
                onTimeUpdate={handleTimeUpdate}
                onPlay={async event => {
                  handleStates('isVideoPause', false);
                  handleStates('videoStartTime', new Date().getTime());
                  // get video current time
                  const currentTime = playerRef.current.getState().player.currentTime;
                  const videoTimeArray = [
                    ...states.videoTimeArray,
                    {
                      startTime: new Date().toLocaleString(),
                      startVideoAt: currentTime,
                      startMiliSecTime: new Date().getTime(),
                    },
                  ];
                  handleStates('videoTimeArray', videoTimeArray);

                  // const totalTime = calculateTotalTime(videoTimeArray);

                  // const acadioUserId = localStorage.getItem('acadioUserId')
                  //   ? JSON.parse(localStorage.getItem('acadioUserId'))
                  //   : '';

                  const data = {
                    // totalTime,
                    videoTimeArray,
                  };

                  console.log('states.videoStateId: ', states.videoStateId);
                  if (states.videoStateId) {
                    data['_id'] = states.videoStateId;
                  } else {
                    data['timestamp'] = new Date().toLocaleString();
                    data['pageUrl'] = window.location.href;
                  }

                  const response = await storeData('/video', data);

                  console.log('response: ', response);
                  if (response?.status) {
                    console.log('response?.data?._id: ', response?.data?._id);
                    handleStates('videoStateId', response?.data?._id);
                  } else {
                    console.error('VIdeo start error: ', response?.message);
                  }
                }}
                onPause={async event => {
                  handleStates('isVideoPause', true);
                  // get video current time
                  const currentTime = playerRef.current.getState().player.currentTime;
                  const videoTimeArray = states.videoTimeArray.map((timeObj, index) =>
                    index === states.videoTimeArray.length - 1
                      ? {
                          ...timeObj,
                          pauseTime: new Date().toLocaleString(),
                          pauseVideoAt: currentTime,
                          pauseMiliSecTime: new Date().getTime(),
                        }
                      : timeObj
                  );
                  handleStates('videoTimeArray', videoTimeArray);

                  // const totalTime = calculateTotalTime(videoTimeArray);
                  if (states.videoStateId) await storeData('/video', { _id: states.videoStateId, videoTimeArray });
                }}
                onEnded={async event => {
                  // get video current time
                  const currentTime = playerRef.current.getState().player.currentTime;

                  const videoTimeArray = states.videoTimeArray.map((timeObj, index) =>
                    index === states.videoTimeArray.length - 1
                      ? {
                          ...timeObj,
                          endTime: new Date().toLocaleString(),
                          endVideoAt: currentTime,
                          endMiliSecTime: new Date().getTime(),
                        }
                      : timeObj
                  );
                  handleStates('videoTimeArray', videoTimeArray);
                  // const totalTime = calculateTotalTime(videoTimeArray);

                  if (states.videoStateId) await storeData('/video', { _id: states.videoStateId, videoTimeArray });
                }}
              >
                <source src={config.video.url} />
                <ControlBar autoHide={true} />
                <BigPlayButton position="center" className="play-icon" />
              </Player>
            </div>
            <div className="px-3 w-100 mb-4">
              <SectionedButton
                invert
                text="Book Free Consultancy Now"
                className="w-100"
                label="Purchase Now"
                // onClick={() => handleStates('showForm', true)}
                onClick={() => {
                  handleSubmit(1);
                  //                  window.location.href = config.razorpay.pageUrl;
                }}
              />
            </div>
            {/* <div className="key-points-main my-3 d-flex flex-row justify-content-center align-items-center mt-4 mb-5">
              <div className="key-points justify-content-start align-items-start bg-transparent m-0 text-white">
                <div className="key-point w-100 hidden-component">
                  <FaCheckCircle color="#ebef00" className="w-2" size={22} />
                  <span>
                    Lorem ipsum <span className="text-bold-yellow">lorem ipsum</span> dolor sit amet.
                  </span>
                </div>
                <div className="key-point w-100 hidden-component">
                  <FaCheckCircle color="#ebef00" className="w-2" size={22} />
                  <span>
                    Lorem ipsum dolor sit <span className="text-bold-yellow">lorem ipsum</span> amet.
                  </span>
                </div>
                <div className="key-point w-100 hidden-component">
                  <FaCheckCircle color="#ebef00" className="w-2" size={22} />
                  <span>
                    Lorem ipsum dolor sit <span className="text-bold-yellow">lorem ipsum amet</span> amet.
                  </span>
                </div>
              </div>
              <div className="key-points justify-content-start align-items-start bg-transparent text-white m-0">
                <div className="key-point w-100 hidden-component">
                  <FaCheckCircle color="#ebef00" className="w-2" size={22} />
                  <span>
                    Lorem ipsum <span className="text-bold-yellow">lorem ipsum sit</span> dolor sit amet.
                  </span>
                </div>
                <div className="key-point w-100 hidden-component">
                  <FaCheckCircle color="#ebef00" className="w-2" size={22} />
                  <span>
                    Lorem <span className="text-bold-yellow">lorem ipsum dolor</span> ipsum dolor sit amet.
                  </span>
                </div>
                <div className="key-point w-100 hidden-component">
                  <FaCheckCircle color="#ebef00" className="w-2" size={22} />
                  <span>
                    <span className="text-bold-yellow">lorem ipsum</span>Lorem ipsum dolor sit amet.
                  </span>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="light-gray-section d-flex flex-column justify-content-center align-items-center">
          <div className="heading mt-4 mb-2 hidden-component">
            If your answer to ANY of the below questions is YES then this consultancy is for YOU
          </div>

          <div className="question-main mt-2 mb-4">
            <div className="d-flex flex-row">
              <div className="check-card hidden-component">
                <div className="d-flex flex-row">
                  <div className="mt-1">
                    <input type="checkbox" id="checkbox1" />
                  </div>
                  <label htmlFor="checkbox1" className="check-label">
                    BCom graduate : Are you stuck in a low-paying job?
                  </label>
                </div>
              </div>
              <div className="check-card hidden-component">
                <div className="d-flex flex-row">
                  <div className="mt-1">
                    <input type="checkbox" id="checkbox2" />
                  </div>
                  <label htmlFor="checkbox2" className="check-label">
                    BCom graduate : Are you struggling to find a high-paying job?
                  </label>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="check-card hidden-component">
                <div className="d-flex flex-row">
                  <div className="mt-1">
                    <input type="checkbox" id="checkbox3" />
                  </div>
                  <label htmlFor="checkbox3" className="check-label">
                    BCom student : Are you worried about getting a good job after graduating?
                  </label>
                </div>
              </div>
              <div className="check-card hidden-component">
                <div className="d-flex flex-row">
                  <div className="mt-1">
                    <input type="checkbox" id="checkbox4" />
                  </div>
                  <label htmlFor="checkbox4" className="check-label">
                    BCom student or graduate : Do you want a high-paying job without investing in expensive
                    certification courses such as CA, CMA, MBA, CPA?
                  </label>
                </div>
              </div>
            </div>
          </div>
          <SectionedButton
            text="Book Free Consultancy Now"
            className="hidden-component mb-4"
            label="Purchase Now"
            // onClick={() => handleStates('showForm', true)}
            onClick={() => {
              handleSubmit(2);
              //              window.location.href = config.razorpay.pageUrl;
            }}
          />
        </div>

        {/* <div className="services mt-3">
          <div className="services-img hidden-component">
            <img className="" src={ServiceImage} alt="Services" />
          </div>
          <div className="services-list">
            <div>
              {services.map((service, index) => {
                return (
                  <div
                    key={"" + service + index}
                    className="d-flex flex-row justify-content-start hidden-component"
                  >
                    <GoDotFill size={20} />
                    <span className="my-2 mx-3">{service}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}

        {/* <div className="white-section d-flex flex-column justify-content-center align-items-center">
          <div className="heading mt-4 mb-2 hidden-component">What You Will Get</div>
          <div className="row w-78-90">
            {services.map((service, index) => (
              <div key={'' + service.title + index} className="col-6 col-md-4 col-lg-4 p-0">
                <div className="service-card hidden-component text-center">
                  <img className="service-image" src={service.image} alt="Students" />
                  <span className="service-text my-1">{service.title}</span>
                  <span className="service-desc my-1">{service.desc}</span>
                </div>
              </div>
            ))}
            BCom graduate : Are you stuck in a low-paying job?
          </div>

          <SectionedButton
            text="Book Free Consultancy Now"
            className="hidden-component"
            label="Purchase Now"
            // onClick={() => handleStates('showForm', true)}
            onClick={() => {
                  handleSubmit(1);
//                  window.location.href = config.razorpay.pageUrl;
                }}
          />
        </div> */}

        {/* <div className="syllabus-main d-flex flex-column justify-content-center align-items-center py-4">
          <div className="syllabus-heading mt-4 hidden-component">
            What You Will learn in this Consultancy for
          </div>
          <div className="row w-85-95 mt-4 mb-4">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 p-0">
              <div className="syllabus-card hidden-component text-center">
                <span className="syllabus-text my-1">
                  Step 1: <span>Lorem, ipsum dolor.</span>
                </span>
                <span className="syllabus-desc mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.{' '}
                  <span>
                    <u>Lorem, ipsum.</u>
                  </span>{' '}
                  Aspernatur omnis aliquam
                  <span>
                    <u>Lorem, ipsum.</u>
                  </span>{' '}
                  expedita enim molestiae eligendi reprehenderit iure dignissimos
                </span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 p-0">
              <div className="syllabus-card hidden-component text-center">
                <span className="syllabus-text my-1">
                  Step 2: <span>Lorem, ipsum dolor.</span>
                </span>
                <span className="syllabus-desc mt-3">
                  Lorem{' '}
                  <span>
                    <u>Lorem, ipsum.</u>
                  </span>{' '}
                  ipsum dolor sit amet consectetur adipisicing elit.{' '}
                  <span>
                    <u>Lorem, ipsum.</u>
                  </span>{' '}
                  Aspernatur omnis aliquam expedita enim molestiae eligendi reprehenderit iure dignissimos
                </span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 p-0">
              <div className="syllabus-card hidden-component text-center">
                <span className="syllabus-text my-1">
                  Step 3: <span>Lorem, ipsum dolor.</span>
                </span>
                <span className="syllabus-desc mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.{' '}
                  <span>
                    <u>Lorem, ipsum.</u>
                  </span>{' '}
                  Aspernatur omnis aliquam expedita enim molestiae eligendi reprehenderit iure dignissimos
                </span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 p-0">
              <div className="syllabus-card hidden-component text-center">
                <span className="syllabus-text my-1">
                  Step 4: <span>Lorem, ipsum dolor.</span>
                </span>
                <span className="syllabus-desc mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.{' '}
                  <span>
                    <u>Lorem, ipsum.</u>
                  </span>{' '}
                  Aspernatur omnis aliquam expedita enim molestiae eligendi
                  <span>
                    <u>Lorem, ipsum. Lorem, ipsum.</u>
                  </span>{' '}
                  reprehenderit iure dignissimos
                </span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 p-0">
              <div className="syllabus-card hidden-component text-center">
                <span className="syllabus-text my-1">
                  Step 5: <span>Lorem, ipsum dolor.</span>
                </span>
                <span className="syllabus-desc mt-3">
                  Lorem ipsum dolor
                  <span>
                    <u>Lorem, ipsum. Lorem, ipsum.</u>
                  </span>{' '}
                  sit amet consectetur adipisicing elit.{' '}
                  <span>
                    <u>Lorem, ipsum.</u>
                  </span>{' '}
                  Aspernatur omnis aliquam expedita enim
                </span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 p-0">
              <div className="syllabus-card hidden-component text-center">
                <span className="syllabus-text my-1">
                  Step 6: <span>Lorem, ipsum dolor.</span>
                </span>
                <span className="syllabus-desc mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.{' '}
                  <span>
                    <u>Lorem, ipsum.</u>
                  </span>{' '}
                  Aspernatur omnis aliquarit{' '}
                  <span>
                    <u>Lorem, ipsum.</u>
                  </span>{' '}
                  iure dignissimos
                </span>
              </div>
            </div>
          </div>

          <SectionedButton
            text="Book Free Consultancy Now"
            className="hidden-component mb-3"
            label="Purchase Now"
            // onClick={() => handleStates('showForm', true)}
            // onClick={() => (window.location.href = config.razorpay.pageUrl)}
            onClick={() => {
                  handleSubmit(1);
//                  window.location.href = config.razorpay.pageUrl;
                }}
          />
        </div> */}

        {/* <div className="d-flex flex-column justify-content-center align-items-center pb-4">
          <div className="heading mt-4 mb-2 hidden-component">Who is this Course for?</div>
          <div className="row w-78-90">
            {persons.map((person, index) => (
              <div key={'' + person.name + index} className="col-6 col-md-4 col-lg-4 p-0">
                <div className="person-card hidden-component text-center">
                  <span className="person-text my-1 py-2">{person.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div> */}

        {/* <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="heading mt-4 mb-2 hidden-component">
            Create Dashboards for all types of data
          </div>
          <div className="row w-85-95">
            {[0, 9, 8, 7, 9, 10].map((num, index) => (
              <div
                key={"example" + num + index}
                className="col-12 col-sm-6 col-md-6 col-lg-4 p-0"
              >
                <div className="dashboard-card hidden-component text-center">
                <div className="dashboard-card hidden-component text-center">
                  <img
                    className="dashboard-image"
                    src={Dashboard}
                    alt="Dashboard"
                  />
                  <span className="dashboard-text py-1 my-2">
                    Business & Sales Dashboards {num}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div> */}

        <div className="white-section d-flex flex-column justify-content-center align-items-center">
          <div className="heading mt-4 mb-2 hidden-component">For Whom is this Consultancy for</div>

          <div className="learn-main mt-2 mb-4">
            <div className="d-flex flex-row">
              <div className="learn-card hidden-component">
                <div className="d-flex flex-row">
                  <div>
                    <img className="image" src={LessSalary} alt="icon" />
                  </div>
                  <div className="text">BCom graduates who are stuck in low paying jobs</div>
                </div>
              </div>
              <div className="learn-card hidden-component">
                <div className="d-flex flex-row">
                  <div>
                    <img className="image" src={HighSalary} alt="icon" />
                  </div>
                  <div className="text">BCom graduates searching for a high-paying job</div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row">
              {/* card */}
              <div className="learn-card hidden-component">
                <div className="d-flex flex-row">
                  <div>
                    <img className="image" src={Confused} alt="icon" />
                  </div>
                  <div className="text">
                    BCom students who want a better career after graduating but are confused or worried about placements
                  </div>
                </div>
              </div>
              <div className="learn-card hidden-component">
                <div className="d-flex flex-row">
                  <div>
                    <img className="image" src={Salary} alt="icon" />
                  </div>
                  <div className="text">
                    BCom students or graduates who want a high-paying job without investing in expensive certification
                    courses like CA, CMA, MBA, CPA, etc
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SectionedButton
            text="Book Free Consultancy Now"
            className="hidden-component"
            label="Purchase Now"
            // onClick={() => handleStates('showForm', true)}
            // onClick={() => (window.location.href = config.razorpay.pageUrl)}
            onClick={() => {
              handleSubmit(3);
              //              window.location.href = config.razorpay.pageUrl;
            }}
          />
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="bonus-heading bg-black w-100 d-flex flex-column justify-content-center align-items-center py-4 text-center px-3 hidden-component">
            <div className="mb-2 text-bold-white">
              Register before midnight of{' '}
              {monthsShortNames[new Date().getMonth()] + ' ' + new Date().getDate() + ', ' + new Date().getFullYear()}{' '}
            </div>
            <div className="text-bold-yellow">To Unlock All Bonuses worth Rs 8,000</div>
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center py-4">
            <div className="bonus-cards-main row w-85-95 mt-2 mb-4">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0 mt-5">
                <div className="bonus-card hidden-component text-center h-100">
                  <div className="bonus-num my-3">
                    <span>Bonus 1</span>
                  </div>
                  <div className="d-flex justify-content-center mt-5">
                    <img style={{ height: '3.5rem' }} src={pdfIcon} alt="pdf" />
                  </div>
                  <span className="bonus-text my-1">PDF of Complete Roadmap to learn Data Analysis</span>
                  <div className="bonus-worth my-3">
                    <span>Worth Rs 1,500</span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0 mt-5">
                <div className="bonus-card hidden-component text-center h-100">
                  <div className="bonus-num my-3">
                    <span>Bonus 2</span>
                  </div>
                  <div className="d-flex justify-content-center mt-5">
                    <img style={{ height: '3.5rem' }} src={pdfIcon} alt="pdf" />
                  </div>
                  <span className="bonus-text my-1">Realtime Projects and Datasets to Work On</span>
                  <div className="bonus-worth my-3">
                    <span>Worth Rs 3,500</span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0 mt-5">
                <div className="bonus-card hidden-component text-center h-100">
                  <div className="bonus-num my-3">
                    <span>Bonus 3</span>
                  </div>
                  <div className="d-flex justify-content-center mt-5">
                    <img style={{ height: '3.5rem' }} src={pdfIcon} alt="pdf" />
                  </div>
                  <span className="bonus-text my-1">Resume Tips and Tricks to Get the Attention of HR</span>
                  <div className="bonus-worth my-3">
                    <span>Worth Rs 1,500</span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0 mt-5">
                <div className="bonus-card hidden-component text-center h-100">
                  <div className="bonus-num my-3">
                    <span>Bonus 4</span>
                  </div>
                  <div className="d-flex justify-content-center mt-5">
                    <img style={{ height: '3.5rem' }} src={pdfIcon} alt="pdf" />
                  </div>
                  <span className="bonus-text my-1">
                    ROI Comparison PDF of Doing Certification Courses Like CA, CPA, CMA, MBA vs. Data Analytics
                  </span>
                  <div className="bonus-worth my-3">
                    <span>Worth Rs 1,500</span>
                  </div>
                </div>
              </div>
            </div>
            <SectionedButton
              text="Book Free Consultancy Now"
              className="hidden-component"
              label="Purchase Now"
              // onClick={() => handleStates('showForm', true)}
              // onClick={() => (window.location.href = config.razorpay.pageUrl)}
              onClick={() => {
                handleSubmit(4);
                //                window.location.href = config.razorpay.pageUrl;
              }}
            />
            <div className="bonus-tagline hidden-component">
              Register before{' '}
              <span>
                {monthsFullNames[new Date().getMonth()] +
                  ' ' +
                  (new Date().getDate() + 1) +
                  ', ' +
                  new Date().getFullYear()}{' '}
              </span>{' '}
              to unlock bonuses worth <span>Rs 8,000</span>
            </div>
          </div>
        </div>

        <div className="mentor-info-main d-flex flex-column justify-content-center align-items-center">
          <div>
            <div className="menter-heading mt-4 mb-2 hidden-component">Know your mentor</div>
            <div className="d-flex mentor-info">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="image hidden-component">
                  <img src={MentorImage} alt="mentorImage" />
                </div>
                <div className="skill-card">
                  <div className="hidden-component">
                    <img src={MentorImage2} alt="mentorImage" />
                  </div>
                  {/* <div className="hidden-component">
                    <img src={MentorImage2} alt="mentorImage" />
                  </div> */}
                </div>
              </div>
              <div className="content ms-4">
                <div>
                  <div className="menter-heading-right mb-2 hidden-component">Know your mentor</div>
                  <div className="menter-title hidden-component">Hi, I am Ziyaad, your consultancy mentor</div>

                  {/* specifications of mentor */}
                  <div className="details fs-6 my-3 hidden-component">
                    <div>
                      <ImPointRight color="#fff" className="me-3" />
                    </div>
                    <div>
                      <span className="text-bold-yellow">General Manager -</span> Tech Uni Academics, in an EdTech
                      company specialized in <span className="text-bold-yellow">Analytics domain</span>.
                    </div>
                  </div>
                  <div className="details fs-6 my-3 hidden-component">
                    <div>
                      <ImPointRight color="#fff" className="me-3" />
                    </div>
                    <div>
                      <span className="text-bold-yellow"></span> I oversee academic programs in{' '}
                      <span className="text-bold-yellow">MBA Business Analytics</span>,{' '}
                      <span className="text-bold-yellow">BCom</span>, <span className="text-bold-yellow">BBA</span> and
                      BTech Computer Engineering.
                    </div>
                  </div>
                  <div className="details fs-6 my-3 hidden-component">
                    <div>
                      <ImPointRight color="#fff" className="me-3" />
                    </div>
                    <div>
                      I have <span className="text-bold-yellow">collaborated</span> with Universities like{' '}
                      <span className="text-bold-yellow">Lovely Professional University (LPU)</span> and{' '}
                      <span className="text-bold-yellow">Jain University, Bangalore</span> to provide{' '}
                      <span className="text-bold-yellow">Data Analytics</span> training to their students
                    </div>
                  </div>
                  <div className="details fs-6 my-3 hidden-component">
                    <div>
                      <ImPointRight color="#fff" className="me-3" />
                    </div>
                    <div>
                      Successfully <span className="text-bold-yellow">trained over 400+</span> working{' '}
                      <span className="text-bold-yellow">corporate professionals</span> and{' '}
                      <span className="text-bold-yellow">students</span> in essential analytics skills and helped them
                      crack a job in <span className="text-bold-yellow">Fortune 500</span> companies.
                    </div>
                  </div>
                  <div className="details fs-6 my-3 hidden-component">
                    <div>
                      <ImPointRight color="#fff" className="me-3" />
                    </div>
                    <div>
                      Passion for making <span className="text-bold-yellow">Data analytics Job</span> accessible and
                      practical for Bcom graduates and students.
                    </div>
                  </div>
                  {/* <div className="details fs-6 my-3 hidden-component">
                    I am the <span className="text-bold-yellow">General Manager</span> of Tech Uni Academics, an EdTech
                    company <span className="text-bold-yellow">specializing in Analytics</span>. My responsibilities
                    include overseeing academic programs in BTech Computer Engineering,{' '}
                    <span className="text-bold-yellow">MBA Business Analytics</span>,{' '}
                    <span className="text-bold-yellow">BCom</span>, and <span className="text-bold-yellow">BBA</span>.
                    Besides academia, I've also{' '}
                    <span className="text-bold-yellow">trained corporate professionals</span> and collaborated with
                    universities like Lovely Professional University (LPU) and Jain University in Bangalore. I have
                    successfully{' '}
                    <span className="text-bold-yellow">trained over 400 working professionals and students</span>,
                    equipping them with essential analytics skills.
                  </div>
                  <div className="details fs-6 my-3 hidden-component">
                    My passion lies in making <span className="text-bold-yellow">analytics education</span> accessible
                    and practical. If you're looking to enhance your analytical abilities, I'm here to help. Join me in
                    your journey to excel in the world of data analysis and presentation, and together, we can make your
                    goals a reality.
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <SectionedButton
            invert
            text="Book Free Consultancy Now"
            className="hidden-component my-4"
            label="Purchase Now"
            // onClick={() => handleStates('showForm', true)}
            // onClick={() => (window.location.href = config.razorpay.pageUrl)}
            onClick={() => {
              handleSubmit(5);
              //              window.location.href = config.razorpay.pageUrl;
            }}
          />
        </div>

        <StickyPurchaseButton
          text="Book Now!"
          // originalPrice="1,000"
          // givenPrice="9"
          // onClick={() => handleStates('showForm', true)}
          // onClick={() => (window.location.href = config.razorpay.pageUrl)}
          onClick={() => {
            handleSubmit(0, 'Sticky');
            //            window.location.href = config.razorpay.pageUrl;
          }}
        />
      </div>
      <UserForm
        show={states.showForm}
        onSuccess={() => handleStates('showSuccess', true)}
        handleHide={() => handleStates('showForm', false)}
      />
      <Success
        show={states.showSuccess}
        headline={headline}
        handleHide={() => {
          handleStates('showSuccess', false);
          navigate('/');
        }}
      />
      {/* <PopupModal
        url={config.calendly.meetingUrl}
        // pageSettings={this.props.pageSettings}
        // utm={this.props.utm}
        // prefill={this.props.prefill}
        onModalClose={() => handleStates('showForm', false)}
        open={states.showForm}
        rootElement={document.getElementById('root')}
      /> */}
    </ScrollVisibilityProvider>
  );
}

export default LandingPage;
